.guesthomepage__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    padding: 10px;
    width: 100%;
}


input[type=text], input[type=number], select {
    width: 100%;
    padding: 0px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #f6f6f6;
    border-radius: 4px;
    box-sizing: border-box;
  }

input[type=submit] {
    width: 100%;
    background-color: #4CAF50;
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.form-group {
    width: 100%;
}

.guesthomepage__wrapper-form {
    width: 100%;
}


.guesthomepage__button {
    background-color: transparent;
    border: 1px gray dotted;
    border-radius: 10px;
    cursor: pointer;
}

.guesthomepage__add {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    margin-top: 0px;
    margin-bottom: 1rem;

}



.guesthomepage__button span {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.guesthomepage__button-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    margin-top: 2rem;
}

.guesthomepage__button-wrapper button{
    margin: 0.5rem 0rem;
}

.loading__screen-center {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    height: 100%;
}


@media screen and (min-width: 1400px) {
    
  
}

@media screen and (max-width: 1100px) {
    
  
}

.guesthome__modal-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }
  
  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #2196F3;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  .information__form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;

  }

  .react-switch {
    vertical-align: middle;
    margin-left: 4px;
  }

  .ghomepage__button-wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
    align-items: center;
}

.ghomepage__button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-radius: 0.5rem;
    padding: 1rem 0rem;
    background-color: #38c5c5;
    border-color: transparent;
    margin: 1rem 0.5rem;
}
  
.ghomepage__button:disabled {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 0.5rem;
  padding: 1rem 0rem;
  background-color: gray;
  border-color: transparent;
  margin: 2rem 0rem;
}