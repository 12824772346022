.signup__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-bottom: 1rem;
}

.signup__form-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.signup__checkbox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    font-size: 1rem;
    margin: 0.5rem 0;
}

.app__checkbox {
    width: 1rem;
    height: 1rem;
    accent-color: gray;
}






.login__button-side {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
}

.login__button-side button{
    padding: 1rem 0rem;
    background-color: #38c5c5;
    border: 1px  white solid;
    margin: 0rem 0rem;
    width: 100%;
    
}

.signup__wrapper input[type="text"],
.signup__wrapper input[type="number"],
.signup__wrapper input[type="password"],
.signup__wrapper input[type="email"],
.signup__wrapper select {
    width: 100%;
    padding: 0px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #f6f6f6;
    border-radius: 4px;
    box-sizing: border-box;
}