.record {
    width: 120px;
    height: 120px;
    margin-top: 20px;
    background-repeat: no-repeat;
    display: flex;
    background-position: center;
    background-size: 100% 100%;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .start,
.recording {
  width: 50px;
  height: 50px;
  border-radius: 25px;
  background-color: #fa7979;
}

.recording {
    border-radius: 10px;
    background-color: #3f3f3f;
  }

.stop {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .process {
    width: 120px;
    height: 120px;
    margin-top: 60px;
    background-repeat: no-repeat;
    display: flex;
    background-position: center;
    background-size: 100% 100%;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .process > img {
    width: 120px;
    height: 120px;
  }

  .hide {
    position: fixed;
    z-index: -1;
    visibility: hidden;
  }

  .record__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .title {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    font-size: 24px;
    color: rgba(56, 197, 197, 1);
  }


  .ready {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .record__button {
    background-color: transparent;
    border: 1px gray dotted;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 1rem;
    padding: 0.5rem;
    
  }

  .loading-screen {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;
  }