.respage__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    /* height: 100%; */
}

.respage__button-wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
    align-items: center;
}

.respage__button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-radius: 0.5rem;
    padding: 1rem 0rem;
    background-color: #38c5c5;
    border-color: transparent;
    margin: 1rem 0.5rem;
}

.respage__button-red {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-radius: 0.5rem;
    padding: 1rem 0rem;
    background-color: #FF5a5a;
    border-color: transparent;
    margin: 1rem 0.5rem;
}

.res__audio {
    width: 100%;
}

.res__audio audio {
    width: 100%;
}

.switch-section {
    justify-content: flex-start;
    width: 100%;
}