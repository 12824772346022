.navbar__wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;

}

.customModal__button-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
}

.createpage__button {
    background-color: transparent;
    border: 1px gray dotted;
    border-radius: 10px;
    cursor: pointer;
}

.navbar__logo {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
    
}

.navbar__logo-guide{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
    
}

.navbar__logo-guide button{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0rem;
}

.navbar__logo button{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0rem;
}

.navbar__wrapper img {
    width: 50%;
    height: 50%;
    cursor: pointer;
}

.navbar__wrapper button{
    background-color: transparent;
    border: 2px transparent solid;

}

.navbar__wrapper span {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.navbar__wrapper a{
    margin: 0.5rem;

}

.button-margin {
    margin-bottom: 1rem;
}

.navbar__logo-link {
    width: 100%;
}

.navbar__wrapper img {
    width: 10rem;
}

.icon {
    font-size: 3rem;
}
@media screen and (max-width: 600px) {

    .icon {
        font-size: 2rem;
    }
    .navbar__wrapper img {
        width: 5rem;
    }



    
  


   
}