.babypage__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
    background-color: #fff;
    padding: 20px;
    width: 100%;
  height: 100%;
  }

  .space {
    height: 15vh;
  }

  .text-overflow h1 {
    color: #39c5c5;
}

  .input-group {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid gray;

  }
  
  .input-group label {
    margin: 1rem 0rem;
    width: 50%;
    /* border-bottom: 2px solid gray; */
    padding: 0rem 1rem;
  }
  
  .input-group input {
    width: 80%;
    padding: 0.5rem; /* your existing padding */
    border: 1px solid transparent; /* your existing border */
    border-radius: 4px; /* your existing border-radius */
    /* border-bottom: 2px solid gray; */
    background-color: transparent; /* Add this line to change the background color */
  }

.input-group input[type=text] {
  width: 80%;
}

  .input-group select{
    border-color: transparent;
    font-size: 1rem;
    padding: 0rem;
    width: 80%;
}

  .babypage__button-wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
    align-items: center;
}

.babypage__button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-radius: 0.5rem;
    padding: 1rem 0rem;
    background-color: #38c5c5;
    border-color: transparent;
    margin: 1rem 0.5rem;
}

.babypage__button-red {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-radius: 0.5rem;
    padding: 1rem 0rem;
    background-color: #FF5a5a;
    border-color: transparent;
    margin: 1rem 0.5rem;
}
  