.grespage__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    /* height: 100%; */
    padding: 1rem;
}

.gresult__wrapper {
    width: 100%;
}


.gresultpage__form {
    background-color: white;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

}

.res__input-group {
    
}

input[type=text], input[type=number], select {
    width: 80%;
  }

.grespage__audio {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: white;
    margin: 1rem;
}

.grespage__audio audio{
    width: 100%;
}

.grespage__button-wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
    align-items: center;
}

.grespage__button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-radius: 0.5rem;
    padding: 1rem 0rem;
    background-color: #38c5c5;
    border-color: transparent;
    margin: 1rem 0.5rem;
}

.grespage__button-red {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-radius: 0.5rem;
    padding: 1rem 0rem;
    background-color: #FF5a5a;
    border-color: transparent;
    margin: 1rem 0.5rem;
}

.res__input-group {
    display: flex;
    flex-direction: row;
    
    width: 100%;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid gray;

  }
  
  .res__input-group label {
    margin: 1rem 0rem;
    width: 50%;
    /* border-bottom: 2px solid gray; */
    padding: 0rem 1rem;
  }
  
  .res__input-group input {
    width: 80%;
    padding: 0.5rem; /* your existing padding */
    border: 1px solid transparent; /* your existing border */
    border-radius: 4px; /* your existing border-radius */
    /* border-bottom: 2px solid gray; */
    background-color: transparent; /* Add this line to change the background color */
  }

  .res__input-group select{
    border-color: transparent;
    font-size: 1rem;
    padding: 0rem;
    width: 80%;
}

.guestresultpage__wrapper-form {
    width: 100%;
} 

.gresultpage__button {
    background-color: transparent;
    border: 1px gray dotted;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    padding: 0.5rem;
}

.gresultpage__button span {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.gresultpage__button-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    margin-top: 2rem;
}

.gresultpage__button-wrapper button{
    margin: 0.5rem 0rem;
}