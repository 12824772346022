
.App {
  width: 100%;
  height: 100%;
  /* background-repeat: no-repeat;
  background-position: center;
  background-size: cover; */
  background-color: white;
  /* margin: 1rem; */
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  
  
}

button {
  color: #000;
}

.app__root {
  height: 100%;
  width: 100%;
  background:url('./assets/img/processBg.svg') ;
  background-repeat: repeat;
  background-position: center;
  background-size:  cover;
}


.invalid-feedback {
  display: block;
  color: red;
  text-align: center;
}

.app__label--required {
  color: red;
  font-size: 20px;
}

.bg {
  width: 50%;
  opacity: 1;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.hideBg {
  opacity: 0;
  transition: all 1s linear;
}

.app__form-style {
  font-size: 2rem;
}

button:hover {
  cursor: pointer;
  opacity: 70%;
  
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 1.5rem;
}

p {
  margin: 1rem;
}

.arabic {
  direction: rtl;
}

@media screen and (max-width: 1200px) {
  .app__form-style {
    font-size: 1.5rem;
  
  }
  h1 {
    font-size: 1.5rem;
  }

  h2 {
    font-size: 1.0rem;
  }
  



   
}