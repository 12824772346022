.login__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-bottom: 1rem;
}

.login__form-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.skip__wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;

}

.skip__wrapper p{
    margin: 0;
}

.skip__wrapper-row{
    display: flex;
    flex-direction: row;
    width: 100%;

}

.skip__wrapper-col{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

}

.login__wrapper-left {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
}

.skip__button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-radius: 0.5rem;
    padding: 1rem 0rem;
    background-color: #38c5c5;
    border-color: transparent;
    margin: 0rem 0rem;
}

.login__button-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-radius: 0.5rem;
    padding: 1rem 0rem;
    background-color: #38c5c5;
    border-color: transparent;
    margin: 0rem 0rem;
}

.login__button-side {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
}

.login__button-side button{
    padding: 1rem 0rem;
    background-color: #38c5c5;
    border: 1px  white solid;
    margin: 0rem 0rem;
    border-radius: 0.5rem;
    width: 100%;
    
}

.app__alert-red {
    font-size: 1rem;
    color: red;
}

.login__wrapper input[type="text"],
.login__wrapper input[type="number"],
.login__wrapper input[type="password"],
.login__wrapper input[type="email"],
.login__wrapper select {
    width: 100%;
    padding: 0px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #f6f6f6;
    border-radius: 4px;
    box-sizing: border-box;
}
