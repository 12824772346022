.createpage__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%
}

.editpage__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%
}

.createpage__wrapper-form {
    width: 100%;
}

.editpage__audio-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 2rem 0rem;
    width: 100%;
}
.editpage__audio-wrapper audio{
    width: 100%;
}

.editpage__button-wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
    align-items: center;
}

.editpage__button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-radius: 0.5rem;
    padding: 1rem 0rem;
    background-color: #38c5c5;
    border-color: transparent;
    margin: 1rem 0.5rem;
}

.editpage__button-red {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-radius: 0.5rem;
    padding: 1rem 0rem;
    background-color: #FF5a5a;
    border-color: transparent;
    margin: 1rem 0.5rem;
}
