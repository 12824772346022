.addpage__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
}

.addpage__form-wrapper {
    width: 100%;
}

.addpage__button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-radius: 0.5rem;
    padding: 1rem 0rem;
    background-color: #38c5c5;
    border-color: transparent;
    margin: 2rem 0rem;
}

.addpage__button:disabled {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-radius: 0.5rem;
    padding: 1rem 0rem;
    background-color: gray;
    border-color: transparent;
    margin: 2rem 0rem;
}

.switch-section {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}