.result {
  margin-top: 60px;
}
.result-item {
  margin-bottom: 1px;

}
.result-item .name {
  color: rgba(20, 21, 34, 1);
  font-size: 15px;
  font-weight: 700;
  margin-bottom: 2px;
  /* position: absolute; */
  z-index: 1;
  
}

.result-item .percent {
  width: 100%;
  min-width: 200px;
  height: 20px;
  border-radius: 20px;
  background: rgba(245, 243, 240, 1);
  overflow: hidden;
  position: relative;
}

.result-item .value {
  height: 20px;
  border-radius: 20px;
  background: rgba(37, 199, 139, 1);
}

.result-item-result {
  margin-bottom: 10px;
  width: 100%;
}

.result-item-result .name {
  color: rgba(20, 21, 34, 1);
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 2px;
  z-index: 1;
  
}

.result__wrapper {
  width:100%;
}


.result-item-result .percent {
  min-width: 200px;
  width: 100%;
  height: 20px;
  border-radius: 20px;
  background: rgba(245, 243, 240, 1);
  overflow: hidden;
}

.result-item-result .value {
  height: 20px;
  border-radius: 20px;
  background: rgba(37, 199, 139, 1);
}

.percent__explain {
  color:gray;
  font-size: 1rem;
}

.name {
  border-bottom: 2px solid gray;
  padding: 0.5rem;
  background-color: white;
}






@media screen and (max-width: 1200px) {
  



   
}

@media screen and (min-width: 1400px) {
  
}
