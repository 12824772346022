.otp__wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.otp-inputs {
    display: flex;
    justify-content: space-between;
    margin:1rem;
  }

.otp-inputs input[type=number]{
  width: 4rem; /* Size as needed */
    height: 4rem; /* Size as needed */
    font-size: 1rem; /* Size as needed */
    text-align: center;
    border: transparent;
    border-bottom: 1px dotted gray;
    margin: 1rem;
}


  
  .otp-input:focus {
    outline: none;
    border-bottom: 2px solid #007bff;
  }
  
  .otp-error input {
    border: 2px red solid;
  }

  @media (max-width: 1025px) {
    .otp-inputs input[type=number]{
      width: 5rem; /* Size as needed */
        height: 5rem; /* Size as needed */
        font-size: 1.2rem; /* Size as needed */
        text-align: center;
        border: transparent;
        border-bottom: 1px dotted gray;
        margin: 1rem;
    }
  }

  @media (max-width: 600px) {

    .otp-inputs input[type=number]{
      width: 3rem; /* Size as needed */
        height: 3rem; /* Size as needed */
        font-size: 1rem; /* Size as needed */
        text-align: center;
        border: transparent;
        border-bottom: 1px dotted gray;
        margin: 1rem 0.2rem ;
    }

    .otp-inputs {
      display: flex;
      justify-content: space-between;
      margin:0rem;
    }

    
  }

  


  