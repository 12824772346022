.homepage__wrapper {
    width: 100%;
    height: 100%;
}

.text-overflow h1 {
    color: #39c5c5;
    padding-left: 0.2rem;

}

.div-red {
    color: red;
    font-size: 1rem;
}


.baby__display-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.baby__display {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0rem 0rem;
}

.hpbaby__display-text {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
    padding: 0rem 0.2rem;
}

.hpbaby__display-text h3{
    padding-right: 0.2rem;
    font-size: 1rem;
    margin-top: 0rem;
    margin-bottom: 0rem;

}



.audio-wrapper {
    width: 100%;
    position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 18%;
  background-color: #fff; /* Or any color that fits your design */
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.3); /* Optional: adds a shadow to the top of the player */
  z-index: 1000; /* Ensures the player stays above other content */

}

.audio-wrapper audio{
    width: 100%;
    height: 40%;

}

.baby__display {
    width: 20%;
}

.baby__display select{
    border-color: transparent;
    font-size: 1.5rem;
    padding: 0rem;
    margin: 0rem;
}

.baby__display option{
    
    padding: 0rem;
    
}


.baby__display-button {
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.baby__display-button button{
    width: 100%;
    margin: 0.0rem;
    background-color: #f5f5f5;
    border-radius: 0.5rem;
    padding: 2px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}


.homepage__hist-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 0.2rem;
}

.bb {
    display: flex;
    flex-direction: row;
    width: 100%;
}


.addrecord__button-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-radius: 0.5rem;
    padding: 1rem 0rem;
    background-color: #38c5c5;
    border-color: transparent;
    margin: 0rem 0rem;
}

.addrecord__button-side {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
}

.addrecord__button-side button{
    padding: 1rem 0rem;
    background-color: #38c5c5;
    border: 1px  white solid;
    margin: 0rem 0rem;
    border-radius: 0.5rem;
    width: 100%;
    
}

.item-name {
    width: 20%;
}





@media screen and (max-width: 1500px) {
    .baby__display {
        width: 23%;
    }
}

@media screen and (max-width: 1000px) {
    .baby__display {
        width: 35%;
    }

    .item-name {
        width: 30%;
    }
    
}


@media screen and (max-width: 600px) {
    .baby__display {
        width: 75%;
    }

    .item-name {
        width: 50%;
    }
}

