.history-list {
    /* Your styles for the list container */
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  .item {
    /* Your styles for list items */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px 0px;
    border: 1px solid transparent;
    border-radius: 1rem;
    margin-bottom: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .item:hover {
    /* Style for hover state */
    /* background-color: #EAF7FA; */
    background-color: #caeff8;
    opacity: 60%;
  }
  
  .selected {
    /* Style for selected/highlighted item */
    background-color: #caeff8;
    /* background-color: black; */
  }
  
  .item-name {
    /* Your styles for the name */
  }
  
  .item-confidence {
    /* Your styles for the confidence */
    font-size: 0.8rem;
    color: #485967;
  }
  
  .item-time {
    /* Your styles for the time */
    margin-top: 4px;
   
  }

  .button-transparent {
    background-color: transparent;
    border-color: transparent;
  }

  