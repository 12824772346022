.input__box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    /* background-color: #f6f6f6; */
    /* padding: 5px 5px;
    border-radius: 5px; */
    width: 100%;
    /* margin: 1rem; */
}
.input__box-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    background-color: #f6f6f6;
    padding: 5px 5px;
    border-radius: 5px;
    width: 100%;
    margin: 0.25rem;
}


.phone__input-wrapper {

    width: 100%;
    margin: 0.7rem 1.5rem;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.phone__input input[type=tel]{
    width: 100%;
}

.phone__input select{
    background-color: black;
    
}

.input__box-wrapper input{
    background-color: #f6f6f6;
    /* border: transparent; */
    /* margin: 0rem 0.5rem; */
    /* height: 100%;*/
    /* width: 100%; */
    font-size: 1rem;
}

.input__icon {
    margin: 0px 5px;
}

.input__righticon {
    margin: 0px 5px;
    height: 2.5rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    
}

.div__righticon {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
